import { getCookie } from '@/utils/custom';
import jwt_decode from 'jwt-decode';
import { TDecodedJwtToken } from '@/common/types/roles.types';
import { TRole } from '@/helpers/types/users.types';

export function isHaveRole(role: TRole): boolean {
  // Не используется класс EX_$Cookies, потому что route.before срабатывает раньше чем created в App в следствии чего теряется контекст
  const token = getCookie('accessToken');
  const clientUUID = getCookie('clientUUID');
  let tokenDecode: any = {};
  // @ts-ignore
  if (token) {
    tokenDecode = jwt_decode(token);
    return tokenDecode?.roles[clientUUID]?.includes(role);
  }
  return false;
}

/**
 * @description Массив ролей
 */
export const getRoles = function (): Array<string> {
  // Не используется класс EX_$Cookies, потому что route.before срабатывает раньше чем created в App в следствии чего теряется контекст
  const token = getCookie('accessToken');
  const clientUUID = getCookie('clientUUID');
  let tokenDecode: any = {};
  // @ts-ignore
  if (token) {
    tokenDecode = jwt_decode(token);
    return tokenDecode?.roles[clientUUID];
  }
  return [];
};

/**
 * @description Имеет ли доступ
 */
export const isHasAccess = function (adminsUUID: Array<{ userUUID: string }>): boolean {
  // Не используется класс EX_$Cookies, потому что route.before срабатывает раньше чем created в App в следствии чего теряется контекст
  const token = getCookie('accessToken');
  const clientUUID = getCookie('clientUUID');
  let hasAccess = false;
  let tokenDecode: any = {};
  // @ts-ignore
  if (token) {
    tokenDecode = jwt_decode(token);
    if (tokenDecode?.roles[clientUUID]?.includes('CLIENT_ADMIN')) {
      return true;
    }
    adminsUUID.forEach(({ userUUID }) => {
      if (tokenDecode?.userUUID === userUUID) hasAccess = true;
    });
  }
  return hasAccess;
};

export function getDecodedJwtToken(): TDecodedJwtToken | null {
  const token = getCookie('accessToken');
  if (token) {
    return jwt_decode<TDecodedJwtToken>(token);
  } else {
    return null;
  }
}

export function getUserUUID(): string | null {
  const decodedJwtToken = getDecodedJwtToken();
  return decodedJwtToken?.userUUID ?? null;
}
