import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/layouts/Layout.vue';
import AdminLayout from '@/layouts/admin/AdminLayout.vue';
import store from '../store/index';
import { getRoles } from '@/utils/custom/roles/Roles';
import { TRouterScrollBehaviour } from '@/common/types/router.types';
import { disabledFeature } from '@/helpers/methods/disabled-feature.method';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'EventSpace',
    component: () => import('@/views/event-space/EventSpace.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/all-events',
    name: 'all-events',
    component: () => import('@/views/all-events/AllEvents.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/personal-account',
    name: 'PersonalAccount',
    component: () => import('@/views/personal-account/PersonalAccount.vue'),
    meta: {
      layout: Layout,
      requiresAuth: true,
    },
  },
  {
    path: '/event/:slug',
    name: 'EventPage',
    component: () => import('@/views/event/EventPage.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/event/:slug/program/:id_program',
    name: 'EventProgramPage',
    component: () => import('@/views/event-program/EventProgramPage.vue'),
    meta: { layout: Layout },
  },
  {
    path: '*',
    name: 'ErrorNotFoundPage',
    component: () => import('@/views/errors/ErrorNotFoundPage.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/error-access-denied',
    name: 'ErrorRoleAccessDenied',
    component: () => import('@/views/errors/ErrorRoleAccessDenied.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/search',
    name: 'SearchPage',
    component: () => import('@/views/search/SearchPage.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: () => import('@/views/profile-page/ProfilePage.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/event/:slug/news/:uuid',
    name: 'NewsPage',
    component: () => import('@/views/news/NewsPage.vue'),
    meta: {
      layout: Layout,
    },
  },
  // ================ Админка ================
  {
    path: '/admin-events',
    name: 'AdminEvents',
    component: () => import('@/views/admin/AdminEvents.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'CLIENT_MANAGER', 'EVENT_ADMIN', 'MMCO_ADMIN', 'EVENT_MANAGER'],
    },
  },
  {
    path: '/create-event',
    name: 'CreateEventPage',
    component: () => import('@/views/admin/create-event/CreateEventPage.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'MMCO_ADMIN', 'EVENT_MANAGER'],
    },
  },
  {
    path: '/constructor-admin-event/:slug',
    name: 'ConstructorEventPage',
    component: () => import('@/views/admin/constructor-event/ConstructorEventPage.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },
  {
    path: '/program-control-panel/:slug',
    name: 'ProgramsControlPanel',
    component: () => import('@/views/admin/ProgramsControlPanel.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'CLIENT_MANAGER', 'EVENT_ADMIN', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },
  {
    path: '/event-redactor/:slug',
    name: 'EventRedactor',
    component: () => import('@/views/admin/event-redactor/EventRedactor.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'CLIENT_MANAGER', 'EVENT_ADMIN', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },
  {
    path: '/admin-event-publication/:slug',
    name: 'AdminEventPublication',
    component: () => import('@/views/admin/event/AdminEventPublication.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },
  {
    path: '/user-management/:slug',
    name: 'UserManagement',
    component: () => import('@/views/admin/user-management/UserManagement.vue'),
    redirect: { name: 'UserManagementEventUsers' },
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'CLIENT_MANAGER', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
    children: [
      {
        path: 'event-users',
        name: 'UserManagementEventUsers',
        component: () => import('@/views/admin/user-management/UserManagementEventUsers.vue'),
        meta: {
          layout: AdminLayout,
          requiresAuth: true,
          accessRoles: [
            'CLIENT_ADMIN',
            'EVENT_ADMIN',
            'CLIENT_MANAGER',
            'MMCO_ADMIN',
            'EVENT_MANAGER',
          ],
          sidebar: true,
        },
      },
      {
        path: 'invited-users',
        name: 'UserManagementInvitedUsers',
        component: () => import('@/views/admin/user-management/UserManagementInvitedUsers.vue'),
        meta: {
          layout: AdminLayout,
          requiresAuth: true,
          accessRoles: [
            'CLIENT_ADMIN',
            'EVENT_ADMIN',
            'CLIENT_MANAGER',
            'MMCO_ADMIN',
            'EVENT_MANAGER',
          ],
          sidebar: true,
        },
      },
    ],
  },
  {
    path: '/admin-partners/:slug',
    name: 'AdminPartners',
    component: () => import('@/views/admin/AdminPartners.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'CLIENT_MANAGER', 'EVENT_ADMIN', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },
  {
    path: '/user-management/:slug/full-statistics',
    name: 'UserManagementFullStatistics',
    component: () => import('@/views/admin/user-management/UserManagementFullStatistics.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'CLIENT_MANAGER', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },
  {
    path: '/admin-news/:slug',
    name: 'AdminNews',
    component: () => import('@/views/admin/admin-news/AdminNews.vue'),
    redirect: { name: 'AdminNewsAll' },
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'CLIENT_MANAGER', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
    children: [
      {
        path: 'all',
        name: 'AdminNewsAll',
        component: () => import('@/views/admin/admin-news/AdminNewsAll.vue'),
        meta: {
          layout: AdminLayout,
          requiresAuth: true,
          accessRoles: [
            'CLIENT_ADMIN',
            'EVENT_ADMIN',
            'CLIENT_MANAGER',
            'MMCO_ADMIN',
            'EVENT_MANAGER',
          ],
          sidebar: true,
        },
      },
      {
        path: 'published',
        name: 'AdminNewsPublished',
        component: () => import('@/views/admin/admin-news/AdminNewsPublished.vue'),
        meta: {
          layout: AdminLayout,
          requiresAuth: true,
          accessRoles: [
            'CLIENT_ADMIN',
            'EVENT_ADMIN',
            'CLIENT_MANAGER',
            'MMCO_ADMIN',
            'EVENT_MANAGER',
          ],
          sidebar: true,
        },
      },
      {
        path: 'unpublished',
        name: 'AdminNewsUnpublished',
        component: () => import('@/views/admin/admin-news/AdminNewsUnpublished.vue'),
        meta: {
          layout: AdminLayout,
          requiresAuth: true,
          accessRoles: [
            'CLIENT_ADMIN',
            'EVENT_ADMIN',
            'CLIENT_MANAGER',
            'MMCO_ADMIN',
            'EVENT_MANAGER',
          ],
          sidebar: true,
        },
      },
    ],
  },
  {
    path: '/admin-news/:slug/redactor',
    name: 'AdminNewsRedactor',
    component: () => import('@/views/admin/admin-news/AdminNewsRedactor.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'CLIENT_MANAGER', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },

  {
    path: '/event-settings/:slug/',
    name: 'EventSettings',
    component: () => import('@/views/admin/event-settings/EventSettings.vue'),
    meta: {
      layout: AdminLayout,
      requiresAuth: true,
      accessRoles: ['CLIENT_ADMIN', 'EVENT_ADMIN', 'CLIENT_MANAGER', 'MMCO_ADMIN', 'EVENT_MANAGER'],
      sidebar: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    // При использовании router push/replace мы можем передать параметры который изменит поведение скрола.
    const scrollBehaviour: TRouterScrollBehaviour = to.params.scrollBehaviour ?? 'default';
    switch (scrollBehaviour) {
      case 'preventDefault':
        return;
      case 'default':
        //Не работает на firefox, решается с помощью setTimeout
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        }, 100);
        break;
      case 'auto':
        window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
        break;
      default:
        document
          .getElementById(scrollBehaviour)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const hasCommon = getRoles()?.some((item) => {
    return to.meta?.accessRoles?.includes(item);
  });
  if (
    to.meta?.requiresAuth &&
    !(store.getters['auth/isLogin'] || document.cookie.includes('accessToken'))
  ) {
    next({ path: '/' });
  } else if (to.meta?.accessRoles?.length && !hasCommon) {
    next({ path: '/' });
  } else {
    next();
  }
  // else if (
  //   (to.meta?.isAdminPage &&
  //     !(document.cookie.includes('isAdmin') || store.getters['auth/isAdmin'])) ||
  //   (to.meta?.isManagerPage &&
  //     !(document.cookie.includes('isManager') || store.getters['auth/isManager']))
  // ) {
  //   next({ path: '/' });
  // } else {
  //   next();
  // }
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'ProfilePage' && to.name !== 'PersonalAccount') {
    // Продолжаем нормальное выполнение, если нет ограничений
    next();
  } else {
    // Вызываем AGetInfo
    await store.dispatch('client/AGetInfo');

    // Проверяем, является ли путь '/personal-account' и у пользователя roleAccess === 'noAdmin'
    if (to.name === 'ProfilePage' && disabledFeature('profile')) {
      // Перенаправляем на страницу accessDenied
      next({ name: 'ErrorRoleAccessDenied' });
    }

    if (to.name === 'PersonalAccount' && disabledFeature('personal_account')) {
      // Перенаправляем на страницу accessDenied
      next({ name: 'ErrorRoleAccessDenied' });
    } else {
      // Продолжаем нормальное выполнение, если нет ограничений
      next();
    }
  }
});

// Хак для того, чтобы убрать ошибку "avoided redundant navigation to current location".
// Ошибка возникает, если переходить на ту же страницу, на которой находишься сейчас.
// В жизни это нормальная практика, например, юзер может нажать на вкладку, чтобы обновить ее.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // @ts-ignore
  return originalPush.call(this, location).catch((e) => {
    if (e.name !== 'NavigationDuplicated') {
      throw e;
    }
  });
};

// Обрабатываем ошибку загрузки чанков https://medium.com/thinkspecial/chunkloaderror-loading-chunk-failed-gopi-k-kancharla-413efbc21db2
router.onError((e) => {
  if (/ChunkLoadError:.*failed./i.test(e.message) || /Loading.*chunk.*failed./i.test(e.message)) {
    window.location.reload();
  } else {
    throw e;
  }
});

export default router;
