import { $Http, EX_$Observer } from '@/classes/base';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Fingerprint } from '@/helpers/classes/fingerprint.class';
import { JwtTokensHelper } from '@/helpers/classes/jwt-tokens-helper.class';

const getAccessToken = (): string => JwtTokensHelper.getAccessToken();

const _baseURL =
  process.env.NODE_ENV !== 'development'
    ? window.location.origin + '/api'
    : process.env.VUE_APP_API_DOMAIN;

const lang =
  process.env.VUE_APP_ENV_STATE === 'PRODUCTION' ? localStorage.getItem('lang') || 'ru' : undefined;
const mmcoClient = process.env.VUE_APP_HARDCODED_MMCO_CLIENT
  ? process.env.VUE_APP_HARDCODED_MMCO_CLIENT
  : undefined;

const $HttpClient = new $Http({
  _baseURL,
  _headers: {
    'Content-type': 'application/json',
    'Accept-Language': lang,
    'x-forward-url': document.URL,
    'Mmco-Client': mmcoClient,
  },
});

$HttpClient.instance.interceptors.request.use(async (config: any) => {
  if (config.url.endsWith('export')) {
    config['responseType'] = 'blob';
    config.headers['accept'] = 'text/csv';
  }
  return config;
});

$HttpClient.instance.interceptors.request.use(async (config: any) => {
  config.headers['x-user-fp'] = Fingerprint.get();
  return config;
});

$HttpClient.instance.interceptors.request.use(async (config: any) => {
  config.headers['accept-language'] = lang;
  const isAccessToken = !!getAccessToken();
  if (isAccessToken) {
    config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  }
  return config;
});

$HttpClient.instance.interceptors.response.use((response: AxiosResponse<any>) => {
  return new Promise((resolve, reject) => {
    if (response.status === 200 || response.status === 201) {
      resolve(response.data);
    } else {
      reject(response);
    }
  });
});

const refreshAndRetryQueue: {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}[] = [];
let isRefreshing = false;
$HttpClient.instance.interceptors.response.use(
  (res) => res,
  async (e) => {
    const originalRequestConfig: AxiosRequestConfig = e.config;

    if (e.response?.status === 500) {
      EX_$Observer.context.$toastr.error('Что-то пошло не так. Повторите попытку позже');
    }

    if (e.response?.status === 401 && JwtTokensHelper.getAccessToken()) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const tokenPair = await JwtTokensHelper.refreshTokenPair();

          if (tokenPair?.accessToken) {
            e.config.headers['Authorization'] = `Bearer ${tokenPair.accessToken}`;
          }

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            $HttpClient.instance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          refreshAndRetryQueue.length = 0;

          return $HttpClient.instance(originalRequestConfig);
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequestConfig, resolve, reject });
      });
    }

    return Promise.reject(e);
  }
);

export default $HttpClient;
