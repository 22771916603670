import {
  IAllEventsInfo,
  IGetAllEventsInfoRequestParams,
  TAdminEvent,
} from '@/services/AdminService/AdminServiceTypes';
import { apiClient } from '@/api/api.client';
import {
  TAdminEventPublicationPage,
  TUpdateEventBody,
  TUpdateEventStatusBody,
  TUpdateEventRegistrationBody,
  TUpdateEventModerationBody,
} from '@/helpers/types/admin/admin-event.types';

export class AdminEventsApi {
  public static async getEvents(params: IGetAllEventsInfoRequestParams): Promise<IAllEventsInfo> {
    params.search = params.search ? params.search : undefined;
    params.timeStatus = params.timeStatus ? params.timeStatus : undefined;
    params.isDeleted = params.isDeleted ? params.isDeleted : undefined;
    params.isPublished = params.isPublished !== null ? params.isPublished : undefined;
    params.isOnModeration = params.isOnModeration ? true : undefined;

    const res = await apiClient.get(`/event-admin`, { params });
    return res.data;
  }

  public static async updateEvent(body: TUpdateEventBody): Promise<void> {
    await apiClient.patch(`/event-admin/${body.uuid}`, body);
  }

  public static async getPublicationPage(slug: string): Promise<TAdminEventPublicationPage> {
    const res = await apiClient.get(`/event-admin/${slug}/publication-page`);
    return res.data;
  }

  public static async updateStatus(slug: string, body: TUpdateEventStatusBody): Promise<void> {
    await apiClient.patch(`/event-admin/${slug}/status`, body);
  }

  public static async updateRegistration(
    slug: string,
    body: TUpdateEventRegistrationBody
  ): Promise<void> {
    await apiClient.patch(`/event-admin/${slug}/registration`, body);
  }

  public static async updateModeration(
    slug: string,
    body: TUpdateEventModerationBody
  ): Promise<TAdminEvent> {
    const res = await apiClient.patch(`/event-admin/${slug}/moderation`, body);

    return res.data;
  }
  public static async removeModeration(slug: string): Promise<TAdminEvent> {
    const res = await apiClient.delete(`/event-admin/${slug}/moderation`);

    return res.data;
  }

  public static async softDelete(slug: string): Promise<void> {
    return await apiClient.put(`event/${slug}/soft-delete`, {});
  }

  public static async recovery(slug: string): Promise<void> {
    return await apiClient.put(`event/${slug}/recover`, {});
  }

  public static async foreverDelete(slug: string): Promise<void> {
    return await apiClient.delete(`event/${slug}`);
  }
}
