import { mapActions } from 'vuex';
import { IAuthPayload } from '@/services/AuthService/AuthServiceTypes';
import Vue from 'vue';
import EX_Toaster from '@/classes/content/toast';
import { EX_$Cookies } from '@/classes/base';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import { YandexMetrikaUtil } from '@/utils/yandex-metrika.util';
import { AuthApi } from '@/api/apis/Auth.api';
import { StatisticsApi } from '@/api/apis/StatisticsApi';
import { JwtTokensHelper } from '@/helpers/classes/jwt-tokens-helper.class';

export default Vue.extend({
  data() {
    return {
      isFetch: false,
      inputValueEmail: '',
      inputValuePassword: '',
      errorTextEmail: [] as Array<string>,
      errorTextPassword: [] as Array<string>,
    };
  },
  methods: {
    ...mapActions('auth', ['ALogout', 'ASetIsLogin']),
    ...mapActions('event', ['AGetMyEventList']),
    checkIsLogin(): void {
      const getAccessToken = JwtTokensHelper.getAccessToken();
      if (getAccessToken) {
        this.ASetIsLogin(true);
        useAuthStore().setIsLoggedIn(true);
        JwtTokensHelper.setRoleInfo(getAccessToken);
      }
    },
    async login(): Promise<void> {
      try {
        this.isFetch = true;

        this.clearTextErrors();

        const body: IAuthPayload = {
          email: this.inputValueEmail.trim().toLowerCase(),
          password: this.inputValuePassword.trim(),
        };
        const res = await AuthApi.loginByEmailAndPassword(body);

        JwtTokensHelper.setAccessToken(res.accessToken);
        JwtTokensHelper.setRoleInfo(res.accessToken);
        JwtTokensHelper.setRefreshToken(res.refreshToken);

        if (process.env.VUE_APP_ENV_STATE === 'LOCAL')
          EX_$Cookies.set('refreshToken', res.refreshToken);

        await this.ASetIsLogin(true);
        useAuthStore().setIsLoggedIn(true);

        this.toggleModal();
        await this.AGetMyEventList();
        EX_Toaster.success('Успешная авторизация');

        if (this.$route.name === 'EventPage') YandexMetrikaUtil.reachGoalVisitEventPage();
        if (this.$route.name === 'EventPage') StatisticsApi.visitEventPage();
      } catch (e: any) {
        const message = e?.response?.data?.message;
        const errors = e?.response?.data?.errors;

        if (errors) {
          if (errors.email) this.errorTextEmail = errors.email;
          if (errors.password) this.errorTextPassword = errors.password;
          return;
        }

        if (message) EX_Toaster.error(message);
      } finally {
        this.isFetch = false;
      }
    },
    async logout(): Promise<void> {
      await this.ALogout();
      useAuthStore().setIsLoggedIn(false);
      if (this.$route.meta?.requiresAuth) this.$router.push('/');
    },
    toggleModal() {
      return this.$emit('toggleModal');
    },
    clearInputData(): void {
      this.inputValueEmail = '';
      this.inputValuePassword = '';
    },
    clearTextErrors(): void {
      this.errorTextEmail = [];
      this.errorTextPassword = [];
    },
  },
  computed: {
    getIsDisabledButton(): boolean {
      return this.inputValuePassword === '' || this.inputValueEmail === '' || this.isFetch;
    },
  },
  mounted() {
    this.clearInputData();
  },
});
