<template>
  <aside class="side-bar" :class="{ 'side-bar--active': isActive }">
    <div
      class="side-bar__container"
      :class="{ 'side-bar__container--active': isActive }"
      :style="isFirstTimeActivated ? '' : 'animation: none;'"
      v-click-outside="onCloseSideBar"
    >
      <div
        class="side-bar__header"
        :class="{ 'side-bar__header--active': isActive }"
        @click="toggleActive"
      >
        <app-text
          v-if="isActive"
          class="side-bar__header-text side-bar__text"
          :class="{ 'side-bar__text--active': isActive }"
          text="Панель управления"
        />
        <app-icon
          :class="{ rotate: isActive }"
          class="side-bar__header-icon"
          name="chevrons-right"
          color="#FFFFFF"
        />
      </div>

      <div class="side-bar__content">
        <div class="side-bar__event-info">
          <img :src="eventInfo.image" alt="Событие" />
          <app-text
            :class="{ 'side-bar__text--active': isActive }"
            v-if="isActive"
            :text="eventInfo.title"
            type="primary"
          />
        </div>
        <div class="side-bar__block-title" />
        <router-link
          :to="{
            name: 'AdminEvents',
            query: { category: 'all' },
            params: {
              scrollBehaviour: `event-${eventInfo.uuid}`,
            },
          }"
          class="side-bar__block-item"
          :class="{ 'side-bar__block-item--active': isActive }"
        >
          <div class="side-bar__indicator" />
          <div class="side-bar__block-item-content" :class="{ 'item-padding-left': !isActive }">
            <app-icon color="#83888F" name="arrow-left" />
            <app-text
              color="secondary"
              v-if="isActive"
              :text="'К событиям'"
              class="side-bar__text"
              :class="{ 'side-bar__text--active': isActive }"
            />
          </div>
        </router-link>
        <div class="side-bar__block-title" />
        <nav class="side-bar__block-content">
          <div
            v-for="(item, index) in settingsFunctionality"
            :key="index"
            @click="onCloseSideBar"
            v-show="!(isManager && item.route === 'admin-event-publication')"
          >
            <router-link
              class="side-bar__block-item group"
              :class="{
                'side-bar__block-item--active': isActive,
              }"
              :to="item.route"
            >
              <div class="side-bar__indicator" />
              <div class="side-bar__block-item-content" :class="{ 'item-padding-left': !isActive }">
                <UIIcon :name="item.iconName" :size="24" :class="getIconColor(item.iconName)" />
                <app-text
                  color="secondary"
                  v-if="isActive"
                  :text="item.title"
                  class="side-bar__text"
                  :class="{ 'side-bar__text--active': isActive }"
                />
              </div>
            </router-link>
          </div>
        </nav>
        <div class="side-bar__block-title" />
        <div class="side-bar__block-content">
          <div
            v-for="(item, index) in controlUsers"
            :key="index"
            class="side-bar__block-item inactive"
            :class="{ 'side-bar__block-item--active': isActive }"
            v-show="!(isManager && (item.iconName === 'tool' || item.iconName === 'gear'))"
          >
            <div class="side-bar__indicator" />
            <div
              class="side-bar__block-item-content-blocked"
              :class="{ 'item-padding-left': !isActive }"
            >
              <div class="side-bar__block-item-name-blocked">
                <UIIcon
                  :name="item.iconName"
                  :size="24"
                  class="min-w-6"
                  :class="getIconColor(item.iconName)"
                />
                <app-text
                  color="secondary"
                  v-if="isActive"
                  :text="item.title"
                  class="side-bar__text"
                  :class="{ 'side-bar__text--active': isActive }"
                />
              </div>
              <div v-if="isActive && item.isBlocked" class="side-bar__soon">
                <img :src="require('@/assets/images/create-event/nextRelease.svg')" alt="Скоро" />
                <app-text type="small" text="скоро" color="text-on-dark-theme" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { isHaveRole } from '@/utils/custom';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import { TUIIconName } from '@/helpers/types/ui.types';
import { ISideBarLink } from '@/helpers/types/layouts.types';
import { IEventInfo } from '@/services/EventService/EventServiceTypes';

export default defineComponent({
  name: 'AdminSidebar',
  components: {
    UIIcon,
    AppIcon,
    AppText,
  },
  props: {
    eventInfo: {
      type: Object as PropType<IEventInfo>,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      settingsFunctionality: [
        {
          title: 'Деловая программа',
          iconName: 'clipboard',
          route: { name: 'ProgramsControlPanel' },
        },
        { title: 'Публикация', iconName: 'calendar', route: { name: 'AdminEventPublication' } },
        { title: 'Список участников', iconName: 'users', route: { name: 'UserManagement' } },
        {
          title: 'Аналитика по регистрациям',
          iconName: 'trending-up',
          route: { name: 'UserManagementFullStatistics' },
        },
        {
          title: 'Настройки события',
          iconName: 'settings',
          route: { name: 'EventSettings' },
        },
        {
          title: 'Новости',
          iconName: 'copy',
          route: { name: 'AdminNews' },
        },
        {
          title: 'Партнеры',
          iconName: 'user-check',
          route: { name: 'AdminPartners' },
        },
      ] as ISideBarLink[],
      controlUsers: [
        { title: 'Опросы и тесты', iconName: 'chart2', isBlocked: true },
        { title: 'Аналитика по деловой программе', iconName: 'trending-up', isBlocked: true },
      ] as ISideBarLink[],
      // используется для костыля - анимация сделана так, что при первом показе на странице, сайдбар визуально открыт.
      // выключаем анимацию до первого открытия сайдбара
      isFirstTimeActivated: false,
    };
  },
  computed: {
    isManager(): boolean {
      return isHaveRole('CLIENT_MANAGER');
    },
  },
  methods: {
    toggleActive() {
      this.isFirstTimeActivated = true;
      this.isActive = !this.isActive;
    },
    onCloseSideBar() {
      this.isActive = false;
    },
    getIconColor(icon: TUIIconName) {
      if (icon === 'chart2') {
        return 'fill-gray group-hover:fill-client';
      }

      return 'fill-none stroke-gray group-hover:stroke-client';
    },
  },
});
</script>

<style lang="scss">
.side-bar__block-item {
  &:hover {
    .side-bar__block-item-content {
      .app-icon {
        color: var(--client-color);
      }
      .app-text {
        color: var(--client-color);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@/ui/styles/colors/index.scss';
@import '~@/ui/styles/font/style.scss';
@import '~@/ui/styles/theme/_color_theme.scss';
.inactive {
  cursor: not-allowed !important;
  grid-template-columns: 4px 373px !important;
  &:hover {
    cursor: not-allowed;
  }
}
.side-bar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  background: transparent;
  z-index: 100;

  &--active {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
  }
  &__text {
    opacity: 0;
    height: 21px;
  }
  &__soon {
    display: flex;
    align-items: center;
    column-gap: 2px;
    box-sizing: border-box;
    padding: 2px 2px;
    background-color: var(--client-color);
    width: 60px;
    height: 26px;
    border-radius: 30px;
  }
  &__indicator {
    width: 4px;
    background-color: #ffffff;
    align-self: normal;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &__text--active {
    opacity: 1;
    height: initial;
    animation: slideRightText 1.5s forwards;
  }
  &__name-event {
    padding: 0 20px;
  }
  &__container {
    background-color: #ffffff;
    width: 62px;
    box-sizing: border-box;
    height: 100%;
    animation: slideLeft 0.5s forwards;
    &--active {
      width: 377px;
      animation: slideRight 0.5s forwards;
    }
  }
  &__event-info {
    display: flex;
    margin: 20px 0;
    padding-left: 6px;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      object-fit: cover;
    }
    .app-text {
      margin-left: 10px;
      -webkit-line-clamp: 2; /* Число отображаемых строк */
      display: -webkit-box; /* Включаем флексбоксы */
      -webkit-box-orient: vertical; /* Вертикальная ориентация */
      overflow: hidden; /* Обрезаем всё за пределами блока */
    }
  }
  &__header {
    background-color: var(--client-color);
    padding: 20px;
    display: flex;
    justify-content: center;
    height: 62px;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    &-text {
      color: #ffffff;
    }
    &-icon {
      transition: transform 0.5s ease;
    }
    &--active {
      justify-content: space-between;
    }
  }
  &__content {
    padding-bottom: 20px;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &__block-title {
    margin: 10px 0;
    border-bottom: 1px solid #dedede;
  }
  &__block-content {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  &__block-item-name-blocked {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
  &__block-item-content-blocked {
    padding: 16px 10px 16px 20px;
    display: grid;
    grid-template-columns: auto 60px;
    align-items: center;
    column-gap: 16px;
  }
  &__block-item {
    display: grid;
    grid-template-columns: 4px auto;
    align-items: center;
    cursor: pointer;
    &-content {
      padding: 16px 14px;
      box-sizing: border-box;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      column-gap: 16px;
    }
    &:hover {
      background-color: #f4f5f6;
      border-radius: 8px;

      .side-bar__indicator {
        background-color: var(--client-color);
      }
    }
    &--active {
      justify-content: start;
    }
  }
}
.item-padding-left {
  //padding: 16px 20px 16px 20px !important;
}
.rotate {
  transform: rotate(180deg);
}

@keyframes slideRight {
  0% {
    width: 62px;
  }
  100% {
    width: 377px;
  }
}

@keyframes slideLeft {
  0% {
    width: 377px;
  }
  100% {
    width: 62px;
  }
}

@keyframes slideRightText {
  0% {
    opacity: 0;
    height: 21px;
  }
  100% {
    opacity: 1;
    height: initial;
  }
}
</style>
