import { EX_$Cookies } from '@/classes/base';
import store from '@/store';
import jwt_decode from 'jwt-decode';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import { get } from 'cross-domain-cookie';
import { AuthService } from '@/services';
import router from '@/router';

export class JwtTokensHelper {
  private static readonly expires: number = 60 * 60 * 24 * 7;

  public static setAccessToken(value: string) {
    // Убираем флаг, так как не работает в сафари
    if (process.env.NODE_ENV === 'development') {
      document.cookie = `accessToken=${value}; max-age=${this.expires}; samesite=lax; path=/`;
    } else {
      document.cookie = `accessToken=${value}; max-age=${this.expires}; secure; samesite=lax; path=/`;
    }
  }

  public static getAccessToken(): string {
    return EX_$Cookies.get('accessToken');
  }

  public static setRefreshToken(value: string) {
    const domain = new URL(process.env.VUE_APP_AUTH_DOMAIN ?? '').hostname; // Получаем домен из URL
    const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString(); // Срок действия куки (7 дней)

    // Устанавливаем куки
    document.cookie = `refreshToken=${encodeURIComponent(
      encodeURIComponent(value ?? '')
    )}; expires=${expires}; domain=${domain}; path=/; secure`;
  }

  public static setRoleInfo(token: string): void {
    // @ts-ignore
    const { roles } = jwt_decode(token);
    // @ts-ignore
    store.dispatch('auth/ASetListRoles', roles[store?.state?.client?.clientInfo?.clientUUID] || []);
  }

  public static removeCookiesTokens(): void {
    EX_$Cookies.remove('accessToken');
    this.setRefreshToken('');
    if (process.env.VUE_APP_ENV_STATE === 'LOCAL') EX_$Cookies.remove('refreshToken');
    store.dispatch('auth/ASetIsLogin', false);
    useAuthStore().setIsLoggedIn(false);
  }

  public static async refreshTokenPair() {
    try {
      // const refreshToken =
      //   process.env.VUE_APP_ENV_STATE != 'LOCAL'
      //     ? await get({
      //         iframeUrl: process.env.VUE_APP_AUTH_DOMAIN,
      //         dataKey: 'refreshToken',
      //       })
      //     : EX_$Cookies.get('refreshToken');
      const refreshToken = EX_$Cookies.get('refreshToken');

      // Обновляем глобальную переменную, потому что многие страницы выполнят рефетч при изменении значения
      await store.dispatch('auth/ASetIsRefreshing', true);
      const res = await AuthService.refreshTokens(refreshToken);
      JwtTokensHelper.setAccessToken(res.accessToken);
      JwtTokensHelper.setRoleInfo(res.accessToken);
      JwtTokensHelper.setRefreshToken(res.refreshToken);
      await store.dispatch('auth/ASetIsLogin', true);
      useAuthStore().setIsLoggedIn(true);

      return res;
    } catch (e) {
      JwtTokensHelper.removeCookiesTokens();
      if (router.currentRoute.meta?.requiresAuth) await router.push('/');
      throw e;
    } finally {
      await store.dispatch('auth/ASetIsRefreshing', false);
    }
  }
}
