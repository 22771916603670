<template>
  <section :class="['app-table', { fetching: isFetch, 'border-outside': isBorderOutside }]">
    <slot>
      <article class="app-table__title">
        <div class="app-table__title_text">
          <app-text :text="title" :type="'main'" />
        </div>
        <slot name="table-controls" />
      </article>
      <article class="app-table__table-container">
        <div class="app-table__table-container-wrapper">
          <table v-if="!isEmptyList" class="app-table__table-container-wrapper_inner">
            <colgroup>
              <col
                v-for="index in headers.length"
                :key="index"
                :style="`width: ${colsWidth[index - 1]}`"
              />
            </colgroup>
            <thead v-if="isHeaderVisible">
              <tr>
                <th
                  :class="[
                    { 'border-under-head': isBorderUnderHead, 'border-right-head': isBorderInside },
                  ]"
                  v-for="item in headers"
                  :key="item.value"
                >
                  <slot :name="`header-${item.value}`" :item="item">
                    <app-text type="secondary" color="secondary" :text="item.text" />
                  </slot>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, rowIndex) in currentList"
                :key="rowIndex"
                :class="{
                  'last-row': !isHasPagination && rowIndex === currentList.length - 1,
                }"
              >
                <td
                  :class="[{ 'border-inside': isBorderInside }]"
                  v-for="cell in headers"
                  :key="cell.value"
                >
                  <div v-if="cell.value === 'actions'" class="settings-col">
                    <app-button-icon
                      color="#83888F"
                      name="eye"
                      icon-size="22"
                      @click="onModal(row)"
                    />
                    <!--                    <app-button-icon icon-size="20" color="#83888F" name="edit" />-->
                  </div>
                  <div v-else-if="cell.value === 'check'" class="checkbox-col">
                    <app-check-box type="checkbox" />
                  </div>
                  <slot
                    v-else
                    :name="`item-${cell.value}`"
                    :item="currentList[rowIndex]"
                    :index="rowIndex"
                  >
                    <app-text type="secondary" :text="defaultValue(rowIndex, cell.value)" />
                  </slot>
                </td>
              </tr>
            </tbody>
          </table>
          <article v-if="isEmptyList" class="app-table__empty">
            <app-text :text="emptyListText || getEmptyListText" />
          </article>
          <article
            v-if="isHasPagination && !isEmptyList"
            class="app-table__table-container-wrapper_pagination"
          >
            <!--            <app-pagination-->
            <!--              v-if="items.length > paginationStep"-->
            <!--              :count="items.length"-->
            <!--              :stepPage="paginationStep"-->
            <!--              @onPaginate="setCurrentList"-->
            <!--            />-->
            <app-pagination
              v-if="totalCount > paginationStep"
              :page="currentPage"
              :count="totalCount"
              :stepPage="paginationStep"
              @onPaginate="onPaginate"
            />
          </article>
        </div>
      </article>
    </slot>
  </section>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppPagination from '@/ui/components/pagination/app-pagination/AppPagination.vue';
import { THeaders, TItems } from './AppTableTypes';
import AppButtonIcon from '@/ui/components/buttons/app-button-icon/AppButtonIcon.vue';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';

export default Vue.extend({
  name: 'AppTable',
  components: { AppCheckBox, AppButtonIcon, AppText, AppPagination },
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    headers: {
      type: Array as PropType<THeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<TItems[]>,
      required: true,
    },
    paginationStep: {
      type: Number,
      default: 10,
    },
    colsWidth: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    emptyListText: {
      type: String,
      required: false,
    },
    isHeaderVisible: {
      type: Boolean,
      default: true,
      required: false,
    },
    isHasPagination: {
      type: Boolean,
      default: true,
    },
    isEmptyList: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFetch: {
      type: Boolean,
      default: false,
      required: false,
    },
    isBorderOutside: {
      type: Boolean,
      default: false,
      required: false,
    },
    isBorderInside: {
      type: Boolean,
      default: false,
      required: false,
    },
    isBorderUnderHead: {
      type: Boolean,
      default: true,
      required: false,
    },
    currentPage: {
      type: Number,
    },
    totalCount: {
      type: Number,
    },
  },
  data() {
    const currentList: Array<TItems> = [];
    return {
      currentList,
    };
  },
  computed: {
    getEmptyListText(): string {
      return 'По данному запросу ничего не найдено';
    },
  },
  watch: {
    items() {
      this.setCurrentList();
    },
  },
  mounted() {
    this.setCurrentList();
  },
  methods: {
    defaultValue(rowIndex: number, cellValue: string): string {
      const currentRow = this.currentList[rowIndex];
      return currentRow[cellValue] ? currentRow[cellValue] : '—';
    },
    setCurrentList(): void {
      this.currentList = this.items;
    },
    onPaginate(page: number) {
      this.$emit('onPaginate', page);
    },
    onModal(value: TItems) {
      this.$emit('onModal', value);
    },
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
