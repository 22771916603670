import { ref } from 'vue';

import { EX_$Toaster } from '@/classes/content';
import axios, { AxiosError } from 'axios';

export function useApiCall<Args>(
  cb: (args: Args) => Promise<void>,
  defaultLoadingState = false,
  catchCb?: (e: any) => void,
  finallyCb?: () => void
) {
  const isLoading = ref(defaultLoadingState);

  const call = async (args: Args) => {
    if (!defaultLoadingState && isLoading.value) return;
    try {
      isLoading.value = true;
      await cb(args);
    } catch (e: any) {
      if (catchCb) {
        catchCb(e);
      } else {
        const errorText = getMessageFromAxiosError(e);
        EX_$Toaster.error(errorText);
      }
    } finally {
      isLoading.value = false;
      finallyCb?.();
    }
  };

  return {
    call,
    isLoading,
  };
}

function getMessageFromAxiosError(e: unknown | AxiosError): string {
  const defaultMessage = 'Произошла ошибка';

  if (axios.isAxiosError(e)) {
    const message = e?.response?.data?.message;

    if (axios.isCancel(e)) {
      return 'Загрузка отменена';
    } else if (typeof message === 'string') {
      return message;
    } else if (Array.isArray(message)) {
      return message[0];
    } else {
      return defaultMessage;
    }
  }

  return defaultMessage;
}
