import { defineStore } from 'pinia';
import store from '@/store';
import router from '@/router';
import { JwtTokensHelper } from '@/helpers/classes/jwt-tokens-helper.class';

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    isLoggedIn: false,
  }),
  actions: {
    setIsLoggedIn(newValue: boolean): void {
      this.isLoggedIn = newValue;
    },
    async checkAccessTokenFirstTime(): Promise<void> {
      const accessToken = JwtTokensHelper.getAccessToken();
      if (accessToken) {
        await store.dispatch('auth/ASetIsLogin', true);
        this.setIsLoggedIn(true);
        JwtTokensHelper.setRoleInfo(accessToken);
      }
    },
    async logout(): Promise<void> {
      await store.dispatch('auth/ALogout');
      if (router.currentRoute.meta?.requiresAuth) await router.push('/');
    },
  },
  getters: {},
});
