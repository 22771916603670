import { RouterUtil } from '@/utils/router.util';

/**
 * @enum EModal - список модальных окан
 */
enum EModal {
  'appModalAuth',
  'appModalRecoveryByEmail',
  'appModalRecoveryByPhone',
  'appModalRecoveryPassword',
  'appModalRegistration',
  'appModalRegistrationPhone',
  'appModalStart',
  'appModalRecognized',
  'appModalRegistrationSuccess',
  'appModalQuizDocuments',
  'appModalQuizPrograms',
  'appModalQuizSpeakers',
  'appModalQuizRecommended',
  'appModalEditCareer',
  'appModalEditUser',
  'appModalEditEducation',
  'appModalEditDescription',
  'appModalAddBlock',
  'appModalQuizSurvey',
  'appModalChangeTitle',
  'appModalEventImage',
  'appModalEventDocuments',
  'appModalEventSpeakers',
  'appModalEventVideo',
  'appModalEventImages',
  // 'appModalCreateProgram',
  'appModalAddTrack',
  'appModalAddLocation',
  'appModalChangeLocation',
  'appModalProgramPresentations',
  'appModalChangeTrack',
  'appModalInviteUser',
  'appModalCalendarBroadcast',
  'appModalCalendarRecord',
  'appModalCalendarSpeakers',
  'appModalReferralLinks',
  'appModalCustomFields',
  'appModalManagementUserProfile',
  'appModalCreateProgramPresentations',
  'appModalConfirmExit',
  'appModalEventSlider',
  'appModalTechnicalWork',
  'appModalAccessDenied',
  'appModalEventSliderFullBlock',
  'appModalEventVideoFullBlock',
  'appModalEventVideoWithTextFullBlock',
  'appModalEventGalleryFullBlock',
  'appModalEventGalleryWithTextFullBlock',
}

export type TModal = keyof typeof EModal;

interface IShowModal {
  toggleModal(OpenCloseModal: TModal): void;
  nextToggleModal(nextModal?: TModal): void;
  getIsShowModal(nameModal: TModal): boolean;
  closeAllModal(): void;
}

/**
 * @class $ShowModal - калсс отображения/скрытия модальных окан
 * @implements IShowModal
 */
class $ShowModal implements IShowModal {
  private stateModals = {
    appModalAuth: false,
    appModalRecoveryByEmail: false,
    appModalRecoveryByPhone: false,
    appModalRecoveryPassword: false,
    appModalRegistration: false,
    appModalRegistrationPhone: false,
    appModalStart: false,
    appModalRecognized: false,
    appModalRegistrationSuccess: false,
    appModalQuizDocuments: false,
    appModalQuizPrograms: false,
    appModalQuizSpeakers: false,
    appModalQuizRecommended: false,
    appModalAddBlock: false,
    appModalEditCareer: false,
    appModalEditUser: false,
    appModalEditEducation: false,
    appModalEditDescription: false,
    appModalQuizSurvey: false,
    appModalChangeTitle: false,
    appModalEventImage: false,
    appModalEventDocuments: false,
    appModalEventSpeakers: false,
    appModalEventVideo: false,
    appModalEventImages: false,
    // appModalCreateProgram: false,
    appModalAddTrack: false,
    appModalAddLocation: false,
    appModalChangeLocation: false,
    appModalProgramPresentations: false,
    appModalChangeTrack: false,
    appModalInviteUser: false,
    appModalCalendarBroadcast: false,
    appModalCalendarRecord: false,
    appModalCalendarSpeakers: false,
    appModalReferralLinks: false,
    appModalCustomFields: false,
    appModalManagementUserProfile: false,
    appModalCreateProgramPresentations: false,
    appModalConfirmExit: false,
    appModalEventSlider: false,
    appModalTechnicalWork: false,
    appModalEventSliderFullBlock: false,
    appModalEventVideoFullBlock: false,
    appModalEventVideoWithTextFullBlock: false,
    appModalEventGalleryFullBlock: false,
    appModalEventGalleryWithTextFullBlock: false,
    appModalAccessDenied: false,
  };

  /**
   * @description Открываем или закрываем конкретную модалку
   */
  public async toggleModal(OpenCloseModal: TModal, withQuery = false): Promise<void> {
    const newState = !this.stateModals[OpenCloseModal];
    if (withQuery) {
      await RouterUtil.changeQuery({ [OpenCloseModal]: newState ? '1' : undefined });
    }
    this.stateModals[OpenCloseModal] = newState;
  }

  /**
   * @description Открываем слудующее модальное окно, предварительно закрываем все остальные окна
   * @param { TModal } nextModal
   */
  public nextToggleModal(nextModal: TModal): void {
    for (const key in this.stateModals) {
      // @ts-ignore
      this.stateModals[key] = false;
    }
    this.stateModals[nextModal] = true;
  }
  /**
   * @description получаем статус конкретного модального окна
   * @param { TModal } nameModal
   * @return { boolean }
   */
  public getIsShowModal(nameModal: TModal): boolean {
    return this.stateModals[nameModal];
  }
  /**
   * @description Закрывает все модальные окна
   */
  public closeAllModal(): void {
    for (const key in this.stateModals) {
      // @ts-ignore
      this.stateModals[key] = false;
    }
  }
}

export default new $ShowModal();
